<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" align-self="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: `${page_route}${!id ? '' : '.view'}`,
                  params: { id: id },
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Interfaz"
                      v-model="data.faq_interface_id"
                      :items="faq_interfaces"
                      item-value="id"
                      :item-text="(item) => item.faq_interface"
                      :loading="faq_interfaces_ldg"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="255"
                      type="text"
                      label="Titulo"
                      v-model="data.title"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      type="text"
                      label="Video (URL)"
                      v-model="data.url_video"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      label="Comentario"
                      v-model="data.comment"
                      :rules="rules.required"
                      rows="6"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ARCHIVOS'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(document, i) in data.documents"
                    :key="i"
                  >
                    <v-row dense v-if="document.active">
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          dense
                          counter
                          maxlength="100"
                          type="text"
                          :label="`Descripción (${i + 1})`"
                          v-model="document.description"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="5">
                        <v-file-input
                          dense
                          show-size
                          label="Archivo digital"
                          v-model="document.file"
                          accept="image/*,.pdf"
                          :rules="data.id ? [] : rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="1" class="text-right">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              small
                              v-on="on"
                              class="mt-1"
                              color="error"
                              @click.prevent="documentDelete(i)"
                            >
                              <v-icon medium> mdi-file-remove </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar documento (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="pb-1" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          fab
                          dark
                          x-small
                          v-on="on"
                          class="mr-1"
                          color="warning"
                          @click.prevent="documentAdd"
                        >
                          <v-icon> mdi-file-plus </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Agregar documento'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  v-on="on"
                  class="ml-1"
                  :loading="loading"
                  :color="!id ? 'success' : 'info'"
                  @click.prevent="handleSubmit"
                >
                  <v-icon>
                    {{ !id ? "mdi-plus" : "mdi-pencil" }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-text="!id ? 'Crear' : 'Editar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  toFormData,
} from "../../control";

export default {
  components: {},

  data() {
    return {
      page_route: "faqs",
      api: "faqs",
      login: this.$store.getters.getLogin,
      loading: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      data: {
        id: null,
        active: true,
        title: "",
        comment: "",
        url_video: "",
        faq_interface_id: null,
        documents: [],
      },
      rules: rules(),
      faq_interfaces: [],
      faq_interfaces_ldg: true,
    };
  },

  methods: {
    documentAdd() {
      this.data.documents.push({
        id: null,
        description: "",
        url: null,
        active: true,
        faq_id: null,
      });
    },
    documentDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el archivo (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    handleSubmit() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${!this.id ? "creación" : "edición"} del registro?`
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;

              let data_form = toFormData(this.data);

              if (this.id) {
                data_form.append("_method", "PATCH");
              }

              Axios.post(
                URL_API + "/" + this.api + (this.id ? "/" + this.id : ""),
                data_form,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                res.data.success
                  ? this.$router.push({
                      name: `${this.page_route}${!this.id ? "" : ".view"}`,
                      params: { id: this.id },
                    })
                  : console.log(res.data.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },

  mounted() {
    Axios.get(
      `${URL_API}/catalog?name=faq_interfaces&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faq_interfaces = resp.data.data;
      this.faq_interfaces_ldg = false;
    });

    if (this.id) {
      this.loading = true;

      Axios.get(
        `${URL_API}/${this.api}/${this.id}`,
        headersToken(this.login.token)
      ).then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
    }
  },
};
</script>